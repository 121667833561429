import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import {useEffect, useState} from "react";

const useFetchApi = () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  console.log('process.env', process.env)
  const isLocalEnv = process.env.EXPO_PUBLIC_IS_LOCAL_ENV == 'true'
  const apiUrl = !isLocalEnv ? "https://api.wingdingos.com/" : "http://api.wingdingos.local:10000/"

  useEffect(() => {
    if (apiUrl) {
      fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            setData(data)
          })
          .catch(error => {
            setError(error)
          })
    } else {
      console.error('apiUrl is not defined')
    }
  }, [])

  return { data, error }
}

export default function App() {

  const { data, error } = useFetchApi()

  if (error) {
    console.error('Error fetching data:', error)
  }

  console.log('API Response:', data)

  const leadText = 'WingDingos?'

  return (
    <View style={styles.container}>
      <Text>{leadText}</Text>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
